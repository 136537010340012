<template>
  <div class="subscribe_layout">
    <div class="content-container">
      <div v-if="$i18n.locale === 'en'" class="terms-content">
        <strong>1. Free Trial Period</strong>
        <br />
        Upon subscribing to the service, you will receive a 24-hour free trial
        period. During this time, you can access all the features and benefits
        of the service without any charge.
        <br />
        <strong>2. Subscription Fee</strong>
        <br />

        After the 24-hour free trial period ends, a daily fee of AED 2.10 (VAT
        included) will be automatically charged to your account. This fee will
        be deducted every day to maintain your subscription and access to the
        service.
        <br />
        <strong>3. Automatic Renewal</strong>
        <br />

        The subscription service is designed to renew automatically after the
        initial free trial period. This means that after the first 24 hours, the
        daily charge of AED 2.10 will continue to be applied without any further
        action required from your side.
        <br />
        <strong>4. No Commitment</strong>
        <br />

        The service offers flexibility with no long-term commitment required.
        You have the freedom to cancel the subscription at any time without any
        penalties or additional charges.
        <br />
        <strong>5. Cancellation Process</strong>
        <br />

        If you wish to cancel your subscription, you can do so easily. To
        cancel, simply send a text message with the code "C HIF" to the number
        1111. Upon sending this message, your subscription will be terminated,
        and you will no longer be charged the daily fee.
      </div>
      <div
        style="direction: rtl; text-align: start"
        class="terms-content"
        v-else
      >
        <strong>1. فترة التجربة المجانية</strong>
        <br />
        عند الاشتراك في الخدمة، ستحصل على فترة تجربة مجانية لمدة 24 ساعة. وخلال
        هذه الفترة، يمكنك الوصول إلى جميع ميزات وفوائد الخدمة دون أي رسوم.

        <br />
        <strong>2. تكلفة الاشتراك</strong>
        <br />

        بعد انتهاء فترة التجربة المجانية لمدة 24 ساعة، سيتم خصم تكلفة يومية
        بقيمة 2.10 درهم إماراتي (بما في ذلك ضريبة القيمة المضافة) تلقائيًا على
        حسابك. سيتم خصم هذه الرسوم يوميًا للحفاظ على اشتراكك والوصول إلى الخدمة.

        <br />
        <strong>3. التجديد التلقائي</strong>
        <br />

        تم تصميم خدمة الاشتراك للتجديد تلقائيًا بعد فترة التجربة المجانية
        الأولية. وهذا يعني أنه بعد أول 24 ساعة، ستستمر التكلفة اليومية بقيمة
        2.10 درهم إماراتي في التطبيق دون الحاجة إلى أي إجراء آخر من جانبك.

        <br />
        <strong>4. عدم الالتزام</strong>
        <br />

        تقدم الخدمة المرونة دون الحاجة إلى التزام طويل الأجل. لديك الحرية في
        إلغاء الاشتراك في أي وقت دون أي عقوبات أو تكلفة إضافية.

        <br />
        <strong>5. عملية الإلغاء</strong>
        <br />

        إذا كنت ترغب في إلغاء اشتراكك، يمكنك القيام بذلك بسهولة. لإلغاء
        الاشتراك، ما عليك سوى إرسال رسالة نصية بالرمز "C HIF" إلى الرقم 1111.
        عند إرسال هذه الرسالة، سيتم إنهاء اشتراكك ولن يتم تحصيل الرسوم اليومية
        منك بعد الآن.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',

  methods: {
    showKurdish () {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode === '964'
    }
  },

  mounted () {
    if (this.showKurdish()) {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.subscribe_layout {
  background-image: url('../../assets/images/subscribe/subscribe_desk_bg.png');
  min-height: 100vh;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    background-image: url('../../assets/images/subscribe/bg_mobile.png');
  }
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  max-width: 600px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.terms-content {
  font-size: 14px;
  color: #212529;
}
</style>
